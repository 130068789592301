import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/Hero'
import HeroVideo from '../components/HeroVideo'
import Main from '../components/Main'
import styled from 'react-emotion'
import Button from '../components/Button'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import Panel from '../components/panel'

import videoHome from '../assets/home-compressed.mp4'
import posterFrame from '../assets/home-poster-sm.jpg'
import HeroVideoHome from '../components/HeroVideoHome'

export default () => (
  <Layout>
    <HeroVideoHome
      cover={videoHome}
      poster={posterFrame}
      headText="CRUISIO"
      subheadText="Dramatically improving fuel efficiency through biomimetic technology"
    ></HeroVideoHome>
    <Main>
      <Panel>
        <h1>Planet Saving</h1>
        <p>We believe in reducing global carbon through dramatic engine efficiency gains</p>
        <Link className="button primary" to="/about">About Cruisio</Link>
      </Panel>
      <Panel className="alt">
        <h1>Money Saving</h1>
        <p>We improve fuel efficiency through safe, patented autonomous micro-hypermiling technology</p>
        <Link className="button primary" to="/services">Services</Link>
      </Panel>
    </Main>
  </Layout>
)
